import { Icon } from "@iconify/react";
import { useState, useEffect,useRef } from "react";
import { ThreeCircles } from "react-loader-spinner";
import RowPeriodo from "./Formulario/RowPeriodo";
import RowPeriodoInterno from "./Formulario/RowPeriodoInterno";
import { ToastContainer, toast } from "react-toastify";
import { Post , baseURL } from "../../App";
function pass(){}
export default function ModalHoras({toggleModal,setToggleModal,uuid,Post,setUpdate,user}) {
  
  const [categorias,setCategorias]=useState([])
  const [categoriasList,setCategoriasList]=useState([])
  const [type,setType]=useState("")
  
  useEffect(()=>{
    console.log("ORIGIN", categorias)
    let cat = []
    for(let i in categorias){
      cat.push({value:categorias[i],label:categorias[i]})
    }
    setCategoriasList(cat)
  },[categorias])
  
  const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
    return [ htmlElRef, setFocus ] 
  }
  const [loader, setLoader] = useState(true);
  const [info, setInfo] = useState([]);
  const [dias, setDias] = useState([]);
  const [activityTypes, setActivityTypes] = useState([]);
  const [activityHourTypes, setActivityHourTypes] = useState([]);
  const [projects, setProjects] = useState([]);
  const [inputRef, setInputFocus] = useFocus();

  useEffect(() => {
    pass(dias);
  }, [dias]);

  useEffect(() => {
    pass(uuid);
    setLoader(true);
    var params = new FormData();
    params.append("public_key","?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L");
    params.append("private_key","+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu");
    params.append("uuid", uuid);
    Post(`periodos/get_by_uuid/`, (response) => {
        setLoader(false);
        setInfo(response.data.info);
        setDias(response.data.dias);
        pass(response);
        // setCategorias(response.data.categorias.sort())
        setCategorias(response.data.categorias)
        setActivityTypes(response.data.activity_types)
        setActivityHourTypes(response.data.activity_hour_types);
        setType(response.data.type);
        setProjects(response.data.projects);
      },
      (error) => {
        setLoader(false);
        pass(error);
      },
      false,
      "Obteniendo datos",
      params
    );
  }, [uuid]);
  function sendActividades() {
    var body = { 
      uuid: uuid, 
      periodoNumber: info[2].value,
      actividades:dias,
      public_key:"?yYD8nCKmB.NdYFJ&iQs$y&{H&9MZ;I_,):;798SUs>/+627H-Rgz.()#[%}t`L",
      private_key:"+n.Q$X8n?lr0RgoSuGO!:@KoQy/NTTRtyPE*xE2SnEwJ>VjS0|Hfzb-{}#|sNSu"
    }
    const requestOptions = {
      method: 'PUT',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    };
    let metodo = user.rol.name=="Empleado" ? "set_actividades/" : "set_actividades_internas/"
    fetch(`${baseURL}/periodos/${metodo}`, requestOptions)
        .then(response => {toast.success("Actividades actualizadas",{theme:"dark"});setUpdate(response)})
        .then(data =>{}).catch((error)=>{toast.error(error,{theme:"dark"})});
  }

  return (
    <>
      <div className={"modal-background"}></div>

      <div className={"modal"} id={"modal"}>
        <div className="relative">
          <div
            className="close absolute pointier"
            onClick={() => setToggleModal(false)}
          >
            <Icon icon={"ic:twotone-close"} />
          </div>
          <div
            className="row hcenter w100 pad20 vcenter"
            style={{ background: "#000", color: "#fff" }}
          >
            <h2 className="row vcenter">Actividades Semanales</h2>
          </div>
        </div>
        {loader ? (
          <div className="row w100 hcenter pad15 vcenter">
            <ThreeCircles
              height="43"
              width="43"
              color="#0061FF"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        ) : (
          <div className="row w100 wrap pad20 hcenter fontSizeSm">
            {info.map((field) => (
              <div className="row col_2 pad10 fontSizeMd">
                <span className="bold">{field.name}:&nbsp;</span> {field.value}
              </div>
            ))}
            <div className="pad20 row w100 hcenter" style={{ maxHeight: 500 }}>
              <div
                className="row hspace wrap w100"
                style={{ overflow: "scroll" }}
              >
                <div className="row w100 wrap">
                  {dias.map((dia) => (
                    user.rol.name=="Empleado"?
                    <RowPeriodo dia={dia} setDias={setDias} dias={dias} inputRef={inputRef} setInputFocus={setInputFocus} categoriasList={categoriasList} categorias={categorias} setCategorias={setCategorias} activityTypes={activityTypes} activityHourTypes={activityHourTypes} type={type} projects={projects} info={info}/>:
                    <RowPeriodoInterno dia={dia} setDias={setDias} dias={dias} inputRef={inputRef} setInputFocus={setInputFocus} categoriasList={categoriasList} categorias={categorias} setCategorias={setCategorias} />
                  ))}
                </div>
              </div>
            </div>
            <div className="row w100 pad10 hcenter">
              <div
                className="btn green active pointier bg_green fontSizeSm bold"
                onClick={sendActividades}
              >
                Guardar
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
